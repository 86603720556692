// 监听验证码
var codeBtn = document.getElementById('validateCodeBtn')
var phoneInput = document.getElementById('freeUsePhone')
var codeInput = document.getElementById('freeUseCode')
var personInput = document.getElementById('freeUsePerson')
var organizeInput = document.getElementById('freeUseOrg')
var time = 60
var timer
codeBtn.addEventListener('click', function() {
  if (time !== 60) return
  const phoneNumber = phoneInput.value
  var phoneReg=/^[1][3,4,5,7,8][0-9]{9}$/;
  const wrongPhone = phoneNumber.length !== 11 || !phoneReg.test(phoneNumber)
  if (wrongPhone) {
    phoneInput.classList.add('error-input')
    return
  }
  phoneInput.classList.remove('error-input')
  fetch(baseUrl + '/clinic/site/trial/code', {
    body: JSON.stringify({
      mobile: phoneNumber
    }),
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      'content-type': 'application/json'
    },
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // *client, no-referrer
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(res) {
      if (res.code === 200) {
        timer = setInterval(() => {
          time--
          if (time === 0) {
            clearInterval(timer)
            time = 60
            codeBtn.innerHTML = '获取验证码'
          } else {
            codeBtn.innerHTML = time + '秒后重试'
          }
        }, 1000)
      }
    });
})

// 监听提交申请按钮
var submitBtn = document.getElementById('submitApplyBtn')
var closeDialogBtn = document.getElementById('closeDialogBtn')
submitBtn.addEventListener('click', function() {
  // 取值
  let phone = phoneInput.value
  let code = codeInput.value
  let person = personInput.value
  let org = organizeInput.value
  if (!phone) {
    phoneInput.classList.add('error-input')
  } else {
    phoneInput.classList.remove('error-input')
  }
  if (!code) {
    codeInput.classList.add('error-input')
  } else {
    codeInput.classList.remove('error-input')
  }
  if (code && phone) {
    fetch(baseUrl + '/clinic/site/trial/submit', {
    body: JSON.stringify({
      mobile: phone,
      code: code,
      name: person,
      clinicName: org
    }),
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      'content-type': 'application/json'
    },
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // *client, no-referrer
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(res) {
      if (res.code === 200) {
        closeDialogBtn.click()
        alert('提交成功，我们将尽快联系您！')
      }
    });
  }
})